import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Grid, useTheme, Collapse, Link } from '@mui/material'
import {
  generateFilters,
  updateSelectedFilters,
  getSelectedFiltersFromQueryString,
} from './helpers'
import { filterDefinitions, UserType } from './filterDefinitions'
import { useIdentity } from 'pages/Identity/Login'
import { useStyles } from './styles'
import { SavedFiltersButton } from './SavedFiltersButton'
import { FilterHeader } from './FilterHeader'
import { MobileFilterDropdown } from './FilterDropdown/MobileFilterDropdown'
import { DesktopFilterDropdown } from './FilterDropdown/DesktopFilterDropdown'
import AlertsButton from './AlertsButton'
import { fetchUserFilterAlerts, fetchFilterTypes } from '../../redux/filters'

export const AuthenticatedFilters = ({ filterType, filterValues, listType, applyFilters }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const styles = useStyles({ mobile })
  const {
    userFilterAlerts = [],
    filterTypes,
  } = useSelector(state => state.filters)
  let ref = useRef(null)
  const { employerId, isEmployerAdmin, userId, employerUserId, candidateId } = useIdentity()
  const userType = employerId || isEmployerAdmin ? UserType.EMPLOYER : UserType.CANDIDATE
  const [searchParams, setSearchParams] = useSearchParams()
  const [areFiltersOpen] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [showAllFilters, setShowAllFilters] = useState(false)
  const filters = generateFilters(filterType, userType, filterValues)
  const dispatch = useDispatch()
  const userFilterAlertParam = searchParams.get('userFilterAlertId')

  useEffect(() => {
    dispatch(fetchFilterTypes())
  }, [])

  useEffect(() => {
    const filtersFromQuery = getSelectedFiltersFromQueryString(searchParams, filterValues)
    setSelectedFilters(prevFilters =>
      JSON.stringify(prevFilters) !== JSON.stringify(filtersFromQuery)
        ? filtersFromQuery
        : prevFilters,
    )
  }, [filterValues, searchParams])

  useEffect(() => {
    applyFilters({ searchParams, employerId, employerUserId, candidateId, isEmployerAdmin })
    Object.keys(selectedFilters).forEach(key => {
      const filterDef = filterDefinitions.find(filter => filter.name === key)
      if (filterDef && filterDef.showMoreFilters) {
        setShowAllFilters(true)
        return true
      }
    })
  }, [selectedFilters])

  useEffect(() => {
    dispatch(fetchUserFilterAlerts(userId))
  }, [userId])

  const userFilterAlertTypeName = listType === 'job' ? 'job' : 'candidate'
  const availableAlerts =
    userFilterAlerts.filter(
      alert =>
        alert.filterType.toLowerCase() === userFilterAlertTypeName && Number(alert.recordCount) > 0,
    ) || []

  const handleFilterChange = selectedFilter => {
    const { filterDefinition, selectedValues } = selectedFilter

    const newSearchParams = filterDefinition.clearOthers
      ? new URLSearchParams()
      : new URLSearchParams(searchParams)

    if (selectedValues.length > 0) {
      const valueString = filterDefinition.isYesNo
        ? selectedValues[0].value === '1'
        : selectedValues.map(x => x.value).join('|')

      newSearchParams.set(filterDefinition.queryStringKey, valueString)
    } else {
      newSearchParams.delete(filterDefinition.queryStringKey)
    }

    setSearchParams(newSearchParams)

    setSelectedFilters(
      selectedValues.length > 0 ? updateSelectedFilters(selectedFilters, selectedFilter) : {},
    )
  }

  const handleClearAll = () => {
    const newSearchParams = new URLSearchParams(searchParams)
    filterDefinitions.forEach(filterDefinition => {
      if (filterDefinition.clearAll === true) {
        newSearchParams.delete(filterDefinition.queryStringKey)
      }
    })

    setSearchParams(newSearchParams)
    setSelectedFilters(currentFilters => {
      const updatedFilters = { ...currentFilters }
      Object.keys(updatedFilters).forEach(key => {
        if (filterDefinitions.find(filter => filter.name === key)) {
          delete updatedFilters[key]
        }
      })
      return updatedFilters
    })
  }

  const toggleShowAllFilters = () => {
    setShowAllFilters(!showAllFilters)
  }

  const renderFilters = filtersToRender => {
    return filtersToRender.map((filter, index) => {
      const selectedFilter = selectedFilters[filter.filterDefinition.name]
      const FilterComponent = mobile ? MobileFilterDropdown : DesktopFilterDropdown
      return (
        <Grid
          item
          key={`${filter.filterDefinition.name}-${index}`}
          xs={12}
          sm={12}
          md={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <FilterComponent
            filterDefinition={filter.filterDefinition}
            options={filter.filterValues}
            checked={selectedFilter ? selectedFilter.selectedValues : null}
            onChange={handleFilterChange}
            styles={styles}
            mobile={mobile}
            filtersOpen={areFiltersOpen}
          />
        </Grid>
      )
    })
  }

  return (
    <div className='joyride_filters'>
      <Grid
        container
        ref={ref}
        justifyContent='center'
        sx={{
          overflow: 'hidden',
          padding: 0,
        }}
      >
        <Grid
          container
          className={`filterWrapper ${styles.filterHeaderWrapper}`}
          sx={{ overflow: 'hidden' }}
        >
          <Grid
            container
            item
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ maxWidth: '70em', overflow: 'hidden' }}
          >
            <FilterHeader
              listType={listType}
              filterValues={filterValues}
              handleFilterChange={handleFilterChange}
              toggleShowAllFilters={toggleShowAllFilters}
              selectedFilters={selectedFilters}
              filterTypes={filterTypes}
              showAllFilters={showAllFilters}
            />
          </Grid>
        </Grid>
        <Grid container className={`filterWrapper ${styles.filterWrapper}`}>
          <Grid
            container
            item
            alignItems='center'
            justifyContent={mobile ? 'center' : 'space-between'} // Center on mobile
            spacing={0}
            sx={{ maxWidth: '70em', overflow: 'hidden', margin: '5px 5px 10px 25px' }}
          >
            {/* Left-aligned Buttons */}
            {!mobile && (
              <Grid item xs={12} sm={6} md={6}>
                <Grid container alignItems='center' spacing={1} justifyContent='flex-start'>
                  <Grid item xs={12} sm={4} md={4}>
                    <div className='joyride_saved_filters' style={{ width: '100%' }}>
                      <SavedFiltersButton
                        listType={listType}
                        variant='contained'
                        size='medium'
                        filterTypes={filterTypes}
                      />
                    </div>
                  </Grid>
                  {availableAlerts.length > 0 && userFilterAlertParam !== null && (
                    <Grid item xs={6} sm={6} md={6}>
                      <AlertsButton availableAlerts={availableAlerts} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {/* Right-aligned Buttons */}
            <Grid item xs={12} sm={6} md={6}>
              <Grid
                container
                spacing={1}
                alignItems='center'
                justifyContent={mobile ? 'center' : 'flex-end'}
              >
                <Grid item>
                  <div className='joyride_save_filters'>{/* Removed SaveFiltersButton */}</div>
                </Grid>
              </Grid>
            </Grid>
            {mobile && (
              <Grid item xs={12}>
                <Grid container alignItems='center' spacing={1} justifyContent='center'>
                  <Grid item xs={8}>
                    <SavedFiltersButton
                      listType={listType}
                      variant='contained'
                      size='medium'
                      filterTypes={filterTypes}
                    />
                  </Grid>
                  {availableAlerts.length > 0 && userFilterAlertParam !== null && (
                    <Grid item xs={8}>
                      <AlertsButton availableAlerts={availableAlerts} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            spacing={0}
            sx={{ maxWidth: '70em', overflow: 'hidden' }}
          >
            <Collapse in={showAllFilters} sx={{ width: '100%' }}>
              <Grid item container direction='row' alignItems='center' spacing={0}>
                {renderFilters(filters)}
                <Grid item>
                  <Link
                    href='#'
                    onClick={handleClearAll}
                    sx={{ color: 'white', marginRight: '15px' }}
                  >
                    Clear Filters
                  </Link>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
