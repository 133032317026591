import React from 'react'
import { useSelector } from 'react-redux'
import { TextField, Autocomplete, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const SubspecialtyField = ({
  name,
  label,
  value = {},
  specialtyId,
  handleChange,
  disabled,
  ...props
}) => {
  const { specialties, subspecialties } = useSelector(state => state.supportingData)
  const [subspecialtyOptions, setSubspecialtyOptions] = React.useState([])

  React.useEffect(() => {
    handleChange({ target: { name: name, value: {} } })
  }, [specialtyId])

  React.useEffect(() => {
    const specialty = specialties.find(x => x.id === specialtyId)
    const newSubspecialtyOptions = subspecialties
      .filter(x => specialty?.subspecialtyIds.includes(x.id) === true)
      .map(x => ({
        id: x.id,
        name: x.name,
      }))
      setSubspecialtyOptions(newSubspecialtyOptions)
  }, [specialtyId, specialties, subspecialties])

  const theme = useTheme()

  const currentValue = value !== null ? { id: value.id, name: value.name } : {}

  return (
    <Autocomplete
      disablePortal
      id={name}
      options={subspecialtyOptions}
      disabled={disabled}
      popupIcon={disabled ? null : <ArrowDropDownIcon />}
      getOptionLabel={option => option.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <TextField
          {...params}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
          label={label}
        />
      )}
      value={currentValue}
      onChange={(event, newValue) => handleChange({ target: { name: name, value: newValue } })}
      {...props}
    />
  )
}

export default SubspecialtyField
