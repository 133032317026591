import React, { useState, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import JoyRideStepper from 'components/JoyRide'
import FeatureIntroDialog from 'components/Dialog/FeatureIntroDialog'
import { useTheme, useMediaQuery, Typography, Link } from '@mui/material'
import { JoyrideContext } from 'components/JoyRide/JoyrideContext'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

const JobsPageTutorial = ({ totalJobs }) => {
  const [showModal, setShowModal] = useState(false)
  const [runTour, setRunTour] = useState(false)
  const { setCurrentStep } = useContext(JoyrideContext)
  const dispatch = useDispatch()
  const tourName = 'recruiterJobsTutorial'
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))
  const { emailVerifiedAt, firstName, employerName } = useSelector(state => state.employerUser)
  const { isEmployerAdmin, email } = useSelector(state => state.identity)
  const stepperStorageVariable = 'hasSeen_' + tourName
  const hasSeen = localStorage.getItem(stepperStorageVariable)

  useEffect(() => {
    if (!hasSeen) {
      setShowModal(true)
    }
  }, [emailVerifiedAt, hasSeen])

  const startTour = () => {
    setShowModal(false)
    setRunTour(true)
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tourName}_start`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
    localStorage.setItem(stepperStorageVariable, true)
  }

  const skipTour = () => {
    setShowModal(false)
    setRunTour(false)
    localStorage.setItem(stepperStorageVariable, true)
    dispatch(
      fetchTrackEvent({
        eventName: `tour_${tourName}_skip`,
        eventType: ProductAnalyticsEventTypes.TOUR_EVENT,
        userId: email,
      }),
    )
  }
  const jobsText = isEmployerAdmin
    ? `all the jobs for ${employerName}`
    : `the jobs you have posted for ${employerName}`
  const header = `👋 Welcome, ${firstName}!`
  const content = `This is where you will see ${jobsText} in MyStethi. Let's have a look around so that you're familiar with the layout.`
  const buttonText = 'Get a tour'
  const steps = [
    ...(totalJobs > 0
      ? [
          {
            target: '.joyride_job_list',
            content: (
              <Typography variant='body1'>
                Here you can view all the jobs you or your team have posted.
              </Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_job_content',
            content: (
              <Typography variant='body1'>
                This section shows the details of the selected job.
              </Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_add_job',
            content: <Typography variant='body1'>Click here to add a new job</Typography>,
            disableBeacon: true,
          },
          {
            target: '.joyride_edit_job',
            content: (
              <Typography variant='body1'>Click here to edit the currently selected job</Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_delete_job',
            content: (
              <Typography variant='body1'>
                Click here to archive the currently selected job. Candidates won't be able to see it
                anymore, but you can always re-enable it later!
              </Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_filters',
            content: (
              <Typography variant='body1'>
                Once you have a list of jobs, use these filters to manage them easier!
              </Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_more_filters',
            content: (
              <Typography variant='body1'>
                Click here to gain access to even more ways to filter!
              </Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_profile_header',
            content: (
              <Typography variant='body1'>
                Access your profile and other settings here from the header. Happy posting!
              </Typography>
            ),
            disableBeacon: true,
          },
        ]
      : [
          {
            target: '.joyride_add_first_job',
            content: (
              <Typography variant='body1'>
                Add your first job to get started! If you would rather us upload them for you, just
                reach out to us at{' '}
                <Link href='mailto:contact@mystethi.com'>contact@mystethi.com</Link>.
              </Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_filters',
            content: (
              <Typography variant='body1'>
                Once you have a list of jobs, use these filters to manage them easier!
              </Typography>
            ),
            disableBeacon: true,
          },
          {
            target: '.joyride_profile_header',
            content: (
              <Typography variant='body1'>
                Access your profile and other settings here from the header. Happy posting!
              </Typography>
            ),
            disableBeacon: true,
          },
        ]),
  ]

  const handleJoyrideCallback = ({ step }) => {
    setCurrentStep(step.index)
  }

  return (
    !below_lg && (
      <>
        <FeatureIntroDialog
          open={showModal}
          onClose={startTour}
          header={header}
          content={content}
          buttonText={buttonText}
          onSkip={skipTour}
        />
        {runTour && (
          <JoyRideStepper
            stepperSteps={steps}
            tourName={tourName}
            callback={handleJoyrideCallback}
          />
        )}
      </>
    )
  )
}

export default JobsPageTutorial
