import React from 'react'
import { useSelector } from 'react-redux'
import { TextField, Autocomplete, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const SpecialtyField = ({
  name,
  label,
  setErrors,
  errors,
  value = {},
  handleChange,
  disabled,
  candidateTypeId,
  ...props
}) => {
  const theme = useTheme()
  const { specialties, candidateTypes } = useSelector(state => state.supportingData)
  const [specialtyOptions, setSpecialtyOptions] = React.useState([specialties])

  React.useEffect(() => {
    const candidateType = candidateTypes.find(x => x.id === candidateTypeId)
    const newSpecialtyOptions = specialties
      .filter(x => candidateType?.specialtyIds.includes(x.id) === true)
      .map(x => ({ id: x.id, name: x.name }))
    setSpecialtyOptions(newSpecialtyOptions)
  }, [candidateTypeId, candidateTypes, specialties])

  const currentValue = value !== null ? { id: value.id, name: value.name } : {}

  const validate = (event, newValue) => {
    if (!newValue) {
      setErrors({ ...errors, specialties: 'Specialty is required' })
    } else {
      delete errors.specialties
    }

    handleChange({
      target: { name: name, value: newValue },
    })
  }
  return (
    <Autocomplete
      disablePortal
      id={name}
      options={specialtyOptions}
      disabled={disabled}
      popupIcon={disabled ? null : <ArrowDropDownIcon />}
      getOptionLabel={option => (option.name ? option.name : '')}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, newValue) => validate(event, newValue)}
      value={currentValue || {}}
      renderInput={params => (
        <TextField
          {...params}
          error={errors.specialties !== undefined}
          helperText={errors.specialties}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
          label={label}
        />
      )}
      {...props}
    />
  )
}

export default SpecialtyField
