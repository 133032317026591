import * as React from 'react'
export const useJobs = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  return {
    handleDrawerClose,
    drawerOpen,
    setDrawerOpen,
  }
}
