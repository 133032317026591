import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Box,
  Checkbox,
  ListItemText,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Link,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import FilterListIcon from '@mui/icons-material/FilterList'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SaveIcon from '@mui/icons-material/Save'
import { useSearchParams } from 'react-router-dom'
import { filterDefinitions } from './filterDefinitions'
import { capitalizeFirstLetter } from 'common/helpers'
import { useSelector } from 'react-redux'
import PhysicianSignUpDialog from '../../components/Dialog/PhysicianSignUpDialog'
import SaveFilterDialog from '../Dialog/SaveFilterDialog'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

export const FilterHeader = ({
  listType,
  filterValues,
  handleFilterChange,
  toggleShowAllFilters,
  selectedFilters,
  filterTypes,
  showAllFilters,
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const [searchParams, setSearchParams] = useSearchParams()
  const { specialties = [], candidateTypes = [] } = filterValues
  const [candidateTypeId, setCandidateTypeId] = useState('')
  const [selectedSpecialties, setSelectedSpecialties] = useState([])
  const { signedIn } = useSelector(state => state.identity)

  const [openSaveDialog, setOpenSaveDialog] = useState(false)
  const [openMoreDialog, setOpenMoreDialog] = useState(false)
  const [openSaveFilterDialog, setOpenSaveFilterDialog] = useState(false)

  const handleSaveDialogClose = () => {
    setOpenSaveDialog(false)
  }

  const handleSaveDialogOpen = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'save_filter_lead_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
      }),
    )
    setOpenSaveDialog(true)
  }

  const handleMoreDialogClose = () => setOpenMoreDialog(false)
  const handleMoreDialogOpen = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'more_filters_lead_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
      }),
    )
    setOpenMoreDialog(true)
  }

  const handleSaveFilterDialogClose = () => setOpenSaveFilterDialog(false)
  const handleSaveFilterDialogOpen = () => setOpenSaveFilterDialog(true)

  useEffect(() => {
    const candidateTypeIdFromParams = searchParams.get('candidateTypeId')
    if (candidateTypeIdFromParams) {
      if (candidateTypeIdFromParams !== candidateTypeId) {
        const selectedProfession = candidateTypes.find(
          option => String(option.value) === candidateTypeIdFromParams,
        )
        if (selectedProfession) {
          setCandidateTypeId(selectedProfession.value)
        } else {
          setCandidateTypeId('')
        }
      }
    } else {
      if (candidateTypeId !== '') {
        setCandidateTypeId('')
      }
    }
  }, [searchParams, candidateTypes])

  useEffect(() => {
    const searchParamSpecialties = searchParams.get('specialtyIds')?.split('|') || []
    const specialtyObjects = searchParamSpecialties.map(id => {
      const specialty = specialties.find(s => s.value === id)
      return specialty ? { name: specialty.name, value: specialty.value } : { name: '', value: id }
    })
    if (JSON.stringify(specialtyObjects) !== JSON.stringify(selectedSpecialties)) {
      setSelectedSpecialties(specialtyObjects.length > 0 ? specialtyObjects : [])
    }
  }, [searchParams, specialties])

  const handleProfessionChange = event => {
    const newSelectedProfession = event.target.value
    const filterDefinition = filterDefinitions.find(filter => filter.name === 'candidateTypes')
    handleFilterChange({
      filterDefinition,
      selectedValues: newSelectedProfession ? [{ value: newSelectedProfession }] : [],
    })

    setCandidateTypeId(newSelectedProfession)
    const newSearchParams = new URLSearchParams(searchParams)
    if (newSelectedProfession) {
      newSearchParams.set('candidateTypeId', String(newSelectedProfession))
    } else {
      newSearchParams.delete('candidateTypeId')
    }
    newSearchParams.delete('specialtyIds')
    setSearchParams(newSearchParams)
    setSelectedSpecialties([])
  }

  const handleSpecialtyChange = event => {
    const value = event.target.value

    if (value.includes('all')) {
      if (selectedSpecialties.length === specialties.length) {
        setSelectedSpecialties([])
        const filterDefinition = filterDefinitions.find(filter => filter.name === 'specialty')
        handleFilterChange({
          filterDefinition,
          selectedValues: [],
        })
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.delete('specialtyIds')
        setSearchParams(newSearchParams)
      } else {
        const allSpecialties = specialties.map(s => ({ name: s.name, value: s.value }))
        setSelectedSpecialties(allSpecialties)
        const filterDefinition = filterDefinitions.find(filter => filter.name === 'specialty')
        handleFilterChange({
          filterDefinition,
          selectedValues: allSpecialties,
        })
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('specialtyIds', specialties.map(s => s.value).join('|'))
        setSearchParams(newSearchParams)
      }
    } else {
      const selectedValues = value.filter(val => val !== 'all')
      const newSelectedSpecialties = selectedValues
        .map(val => {
          const specialty = specialties.find(s => s.value === val)
          return specialty ? { name: specialty.name, value: specialty.value } : null
        })
        .filter(s => s !== null)

      setSelectedSpecialties(newSelectedSpecialties)

      const filterDefinition = filterDefinitions.find(filter => filter.name === 'specialty')
      handleFilterChange({
        filterDefinition,
        selectedValues: newSelectedSpecialties,
      })

      const newSearchParams = new URLSearchParams(searchParams)
      if (newSelectedSpecialties.length > 0) {
        newSearchParams.set('specialtyIds', newSelectedSpecialties.map(s => s.value).join('|'))
      } else {
        newSearchParams.delete('specialtyIds')
      }
      setSearchParams(newSearchParams)
    }
  }

  const clearProfession = () => {
    setCandidateTypeId('')
    handleProfessionChange({ target: { value: '' } })
  }

  const clearSpecialties = () => {
    setSelectedSpecialties([])
    handleSpecialtyChange({ target: { value: [] } })
  }

  const specialtyLabel =
    specialties.length === 0
      ? ''
      : selectedSpecialties.length > 1 || selectedSpecialties.length === 0
        ? 'Specialties'
        : 'Specialty'
  const type = listType === 'job' ? 'Jobs' : 'Providers'
  const jobsText = `${capitalizeFirstLetter(type)}`

  const handleSaveLinkClick = event => {
    if (!signedIn) {
      event.preventDefault()
      handleSaveDialogOpen()
    } else {
      handleSaveFilterDialogOpen()
    }
  }

  const handleMoreLinkClick = event => {
    if (!signedIn) {
      event.preventDefault()
      handleMoreDialogOpen()
    } else {
      toggleShowAllFilters()
    }
  }

  return (
    <>
      <Grid
        container
        direction='row'
        xs={12}
        justifyContent={mobile ? 'center' : 'flex-start'}
        alignItems='center'
        spacing={mobile ? 2 : 0}
        sx={{
          margin: '0px 10px',
          backgroundColor: theme.palette.primary.dark,
          padding: '10px 0px',
          borderRadius: '5px',
          overflow: 'hidden',
          textAlign: 'center',
          flexWrap: mobile ? 'wrap' : 'nowrap',
        }}
      >
        <Grid item xs={12} container alignItems='center' justifyContent='flex-start'>
          <Box
            display='flex'
            alignItems='center'
            color='white'
            flexDirection={mobile ? 'column' : 'row'}
            justifyContent='flex-start'
            width='100%'
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px',
              }}
            >
              {!mobile && <FilterListIcon sx={{ color: 'white' }} />}
            </Box>
            <Grid
              container
              flexDirection='row'
              xs={11}
              justifyContent='flex-start'
              alignItems='center'
              sx={{ textAlign: 'left' }}
            >
              <Grid item xs={8} md={3}>
                <FormControl
                  variant='standard'
                  sx={{
                    margin: '0',
                    minWidth: 100,
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    width: mobile ? '90%' : '100%', // Adjusted to take up less space on larger screens
                    marginBottom: mobile ? '10px' : 0,
                  }}
                >
                  <Select
                    displayEmpty
                    value={candidateTypeId || ''}
                    onChange={handleProfessionChange}
                    disableUnderline
                    variant='standard'
                    endAdornment={
                      candidateTypeId && (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={clearProfession}
                            sx={{ padding: 0, fontSize: '16px', color: theme.palette.primary.main }}
                          >
                            <ClearIcon fontSize='small' />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                    renderValue={selected => {
                      if (!selected || selected.length === 0) {
                        return <>All</>
                      }
                      return (
                        candidateTypes.find(option => String(option.value) === String(selected))
                          ?.name || ''
                      )
                    }}
                    sx={{
                      color: theme.palette.primary.dark,
                      '& .MuiSelect-select': { padding: '0 10px' },
                      backgroundColor: 'transparent',
                      '& .MuiSelect-icon': { right: '30px' },
                      padding: '5px',
                    }}
                  >
                    <MenuItem value=''>All</MenuItem>
                    {candidateTypes.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4} md={2}>
                <Typography
                  variant={mobile ? 'body2' : 'h6'}
                  color='inherit'
                  noWrap
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: mobile ? '0' : '10px',
                    marginTop: mobile ? '0px' : '0',
                    whiteSpace: 'nowrap', // Prevents text from wrapping
                  }}
                >
                  {jobsText}
                </Typography>
              </Grid>

              {specialties.length > 0 && (
                <Grid item xs={8} md={3}>
                  <FormControl
                    variant='standard'
                    sx={{
                      margin: '0',
                      minWidth: 100,
                      backgroundColor: 'white',
                      borderRadius: '4px',
                      width: mobile ? '90%' : '100%', // Adjusted to take up less space on larger screens
                      marginBottom: mobile ? '10px' : 0,
                    }}
                  >
                    <Select
                      multiple
                      displayEmpty
                      value={
                        selectedSpecialties.length === 0
                          ? []
                          : selectedSpecialties.length === specialties.length
                            ? ['all']
                            : selectedSpecialties.map(s => s.value)
                      }
                      onChange={handleSpecialtyChange}
                      disableUnderline
                      variant='standard'
                      endAdornment={
                        selectedSpecialties.length > 0 && (
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={clearSpecialties}
                              sx={{
                                padding: 0,
                                fontSize: '16px',
                                color: theme.palette.primary.main,
                              }}
                            >
                              <ClearIcon fontSize='small' />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      renderValue={selected => {
                        if (selected.length === 0 || selected.includes('all')) {
                          return 'All'
                        }
                        return selected
                          .filter(value => value && value !== 'all')
                          .map(value => specialties.find(s => s.value === value)?.name || '')
                          .join(', ')
                      }}
                      sx={{
                        color: theme.palette.primary.dark,
                        '& .MuiSelect-select': { padding: '0 10px' },
                        backgroundColor: 'transparent',
                        '& .MuiSelect-icon': { right: '30px' },
                        padding: '5px',
                      }}
                    >
                      <MenuItem key='all' value='all'>
                        <Checkbox
                          checked={selectedSpecialties.length === specialties.length}
                          indeterminate={
                            selectedSpecialties.length > 0 &&
                            selectedSpecialties.length < specialties.length
                          }
                        />
                        <ListItemText primary='All' />
                      </MenuItem>
                      {specialties.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox
                            checked={selectedSpecialties.some(s => s.value === option.value)}
                          />
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={4} md={2}>
                <Typography
                  variant={mobile ? 'body2' : 'h6'}
                  color='inherit'
                  noWrap
                  sx={{
                    fontWeight: 'bold',
                    marginLeft: mobile ? '0' : '10px',
                    marginTop: mobile ? '5px' : '0',
                    whiteSpace: 'nowrap', // Prevents text from wrapping
                  }}
                >
                  {specialtyLabel}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12} sm={3} container justifyContent='flex-end'>
          <Box
            sx={{
              marginRight: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '10px', // Adds some spacing between the items
            }}
          >
            <div className='joyride_more_filters' style={{ display: 'flex', alignItems: 'center' }}>
              <Link
                href='#'
                onClick={handleMoreLinkClick}
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                  whiteSpace: 'nowrap', // Ensure text doesn't wrap
                }}
              >
                {showAllFilters ? 'Hide Filters' : 'More Filters'}
              </Link>
              <ExpandMoreIcon
                sx={{
                  marginLeft: '5px',
                  fontSize: '16px',
                  verticalAlign: 'middle',
                  color: 'white',
                  transform: showAllFilters ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                  marginRight: '15px',
                }}
              />
            </div>

            <div className='joyride_save_filters' style={{ display: 'flex', alignItems: 'center' }}>
              <Link
                href='#'
                onClick={handleSaveLinkClick}
                sx={{
                  color: 'white',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                  whiteSpace: 'nowrap', // Ensure text doesn't wrap
                }}
              >
                Save Filters
              </Link>
              <SaveIcon
                sx={{
                  marginLeft: '5px',
                  fontSize: '16px',
                  verticalAlign: 'middle',
                  color: 'white',
                }}
              />
            </div>
          </Box>
        </Grid>
      </Grid>

      <PhysicianSignUpDialog
        openDialog={openSaveDialog}
        handleDialogClose={handleSaveDialogClose}
        feature='save'
      />
      <PhysicianSignUpDialog
        openDialog={openMoreDialog}
        handleDialogClose={handleMoreDialogClose}
        feature='more'
      />
      <SaveFilterDialog
        openDialog={openSaveFilterDialog}
        handleDialogClose={handleSaveFilterDialogClose}
        selectedFilters={selectedFilters}
        filterTypes={filterTypes}
        listType={listType}
      />
    </>
  )
}

export default FilterHeader
