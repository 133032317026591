import React from 'react'
import { useIdentity } from 'pages/Identity/Login'
import { AuthenticatedFilters } from './AuthenticatedFilters'
import { PublicFilters } from './PublicFilters'

export const Filters = ({ filterType, filterValues, listType, applyFilters }) => {
  const { signedIn } = useIdentity()

  return signedIn ? (
    <AuthenticatedFilters
      filterType={filterType}
      filterValues={filterValues}
      listType={listType}
      applyFilters={applyFilters}
    />
  ) : (
    <PublicFilters
      filterType={filterType}
      filterValues={filterValues}
      listType={listType}
      applyFilters={applyFilters}
    />
  )
}
