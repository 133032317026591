import React, { useEffect } from 'react'
import GenericDialog from 'components/Dialog/GenericDialog'
import { copyToLower } from 'data/helpers'
import { useDialogState } from 'data/dialog/actions'
import { useSpecialties } from 'data/layout/actions'
import { States, getHourlySalaryRange, getAnnualSalaryRange } from 'data/constants'
import {
  getRawItems,
  PRACTICE_TYPE_ENUM,
  AREA_ENUM,
  ACADEMIC_ENUM,
  VISA_ENUM,
  SHIFT_TYPE_ENUM,
  SALARY_TYPE_ENUM,
} from 'data/enums'
import { useJob } from 'data/job/hooks'
import { useIdentity } from 'pages/Identity/Login'
import { isEdit, isView } from 'components/Dialog/helpers'

export const dialogId = 'Job'

const handleValidation = (values, dialogState) => {
  var errors = copyToLower(dialogState.errors)
  return errors
}

const defaultValues = {}

export default function JobDialog({ id = dialogId, onResponse: OnResponse = undefined }) {
  const { specialties, subspecialties, candidateTypes } = useSpecialties(open)
  const [
    {
      open,
      type,
      values = {},
      initialValues,
      formSpecialties = specialties,
      formSubspecialties = subspecialties,
      salaryRange,
    },
    setState,
  ] = useDialogState(id)
  const { employerUserId, isEmployerAdmin } = useIdentity()
  const [, , setJob, createJob] = useJob()

  // Filter specialties when candidate type changes
  useEffect(() => {
    const newFormSpecialties = values.candidateTypeId
      ? specialties.filter(s =>
          candidateTypes.find(x => x.id === values.candidateTypeId)?.specialtyIds?.includes(s.id),
        )
      : []
    setState(prev => ({
      ...prev,
      formSpecialties: newFormSpecialties,
      formSubspecialties: [],
    }))
  }, [values.candidateTypeId, open, specialties])

  // Filter subspecialties when specialty changes
  useEffect(() => {
    // Ensure specialtyIds is an array
    const specialtyIds = Array.isArray(values.specialtyIds)
      ? values.specialtyIds
      : [values.specialtyIds].filter(Boolean)

    if (specialtyIds.length > 0) {
      const newFormSubspecialties = subspecialties.filter(s =>
        specialties.some(x => specialtyIds.includes(x.id) && x.subspecialtyIds?.includes(s.id)),
      )
      setState(prev => ({
        ...prev,
        formSubspecialties: newFormSubspecialties,
      }))
    }
  }, [values.specialtyIds, initialValues.specialtyIds, open, subspecialties, specialties])

  // Set salary type and candidate type on load
  useEffect(() => {
    if (values['salaryType']) {
      if (values['salaryType'] === 'Annual') {
        setState(prev => ({ ...prev, salaryRange: getAnnualSalaryRange().values }))
      } else {
        setState(prev => ({ ...prev, salaryRange: getHourlySalaryRange().values }))
      }
    }

    // Check if initialValues has candidateTypeId and set it
    if (initialValues.candidateTypeId) {
      setState(prev => ({ ...prev, candidateTypeId: initialValues.candidateTypeId }))
    }

    // Set specialtyIds based on specialties in initialValues
    if (initialValues.specialties) {
      const specialtyIds = initialValues.specialties.map(specialty => specialty.id)
      setState(prev => ({ ...prev, specialtyIds }))
    }
  }, [values, open, initialValues])

  // Initialize the form state on load
  useEffect(() => {
    setState(prev => ({
      ...prev,
      initialValues,
      open,
      type,
      values: initialValues || {},
      candidateTypeId: initialValues.candidateTypeId || null,
      value: {},
      formSpecialties: specialties,
      formSubspecialties: subspecialties,
      salaryRange: getAnnualSalaryRange().values,
    }))
  }, [initialValues, specialties, subspecialties])
  // Handle form submit
  const handleSubmit = React.useCallback(
    values => {
      values = {
        ...values,
        specialtyIds: values.specialtyIds || [],
        subspecialtyIds: values.subspecialtyIds || [], // Handle multiple subspecialties
        employerUserId,
        areaType: values.area,
        academicType: values.candidateTypeId === 1 ? values.academic : null,
        shiftType: values.candidateTypeId === 2 ? values.shiftType : null,
      }
      isEdit(type)
        ? setJob(values, setState, onResponse)
        : createJob(values, setState, onResponse, isEmployerAdmin)
      setState(prev => ({ ...prev, candidateTypeId: null }))
    },
    [type, setJob, createJob, employerUserId, setState, onResponse],
  )

  const onResponse = React.useCallback(
    response => {
      OnResponse && OnResponse(response)
    },
    [OnResponse],
  )

  const about = isEdit(type) ? initialValues.about : null

  return (
    <GenericDialog
      id={id}
      title={id}
      maxWidth='md'
      onSubmit={handleSubmit}
      validate={handleValidation}
      initialValues={isView(type) || isEdit(type) ? initialValues : defaultValues}
      submitLabel='Save'
      cancelLabel={isView(type) ? 'Close' : 'Cancel'}
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'employerUserId',
          hidden: true,
        },
        {
          id: 'candidateTypeId',
          label: 'Please choose a job type first',
          dialogVar: 'candidateTypeId',
          type: 'autocomplete',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          dialogId: dialogId,
          items: candidateTypes.map(k => ({
            value: k.id,
            label: k.name,
          })),
          margin: 'dense',
          disableClearable: true,
          xs: 6,
        },
        {
          id: 'practiceType',
          label: 'Practice Type',
          type: 'autocomplete',
          required: true,
          forceErrorMargin: false,
          disableClearable: true,
          variant: 'outlined',
          items: getRawItems(PRACTICE_TYPE_ENUM),
          margin: 'dense',
          xs: 6,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'shiftType',
          label: 'Shift Type',
          type: 'autocomplete',
          hidden:
            (values['candidateTypeId'] === undefined &&
              initialValues['candidateTypeId'] === undefined) ||
            initialValues['candidateTypeId'] !== 2,
          required: false,
          forceErrorMargin: false,
          disableClearable: true,
          defaultValue: null,
          variant: 'outlined',
          items: getRawItems(SHIFT_TYPE_ENUM),
          margin: 'dense',
          xs: 12,
        },
        {
          id: 'title',
          label: 'Job Title',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
        },
        {
          id: 'city',
          label: 'City',
          placeholder: 'city',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          xs: 6,
        },
        {
          id: 'state',
          label: 'State',
          type: 'autocomplete',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          items: Object.keys(States).map(k => ({ value: k, label: `${States[k]}` })),
          margin: 'dense',
          disableClearable: true,
          xs: 6,
        },
        {
          id: 'specialtyIds',
          type: 'autocomplete',
          label: 'Specialties',
          xs: 6,
          multiple: true, // Allow multiple specialties
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          items: formSpecialties.map(s => ({ value: s.id, label: s.name })),
          hidden:
            formSpecialties.length === 0 ||
            (values['candidateTypeId'] === undefined &&
              initialValues['candidateTypeId'] === undefined),
        },
        {
          id: 'subspecialtyIds',
          type: 'autocomplete',
          label: 'Subspecialties',
          xs: 6,
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          multiple: true, // Allow multiple subspecialties
          margin: 'dense',
          items: formSubspecialties.map(s => ({ value: s.id, label: s.name })),
          hidden:
            formSubspecialties.length === 0 ||
            (values['candidateTypeId'] === undefined &&
              initialValues['candidateTypeId'] === undefined),
        },
        {
          id: 'visas',
          label: 'Visa Sponsorship',
          type: 'autocomplete',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          items: getRawItems(VISA_ENUM),
          margin: 'dense',
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
          xs: 4,
        },
        {
          id: 'loanForgiveness',
          label: 'Loan Forgiveness',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          required: false,
          variant: 'outlined',
          items: [
            { value: false, label: 'No' },
            { value: true, label: 'Yes' },
          ],
          margin: 'dense',
          xs: 4,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'pslfCompatible',
          label: 'PSLF Compatible',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          required: false,
          variant: 'outlined',
          items: [
            { value: false, label: 'No' },
            { value: true, label: 'Yes' },
          ],
          margin: 'dense',
          xs: 4,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'area',
          label: 'Area',
          type: 'autocomplete',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          items: getRawItems(AREA_ENUM),
          margin: 'dense',
          xs: 6,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'academic',
          label: 'Academic',
          type: 'autocomplete',
          forceErrorMargin: false,
          required: false,
          variant: 'outlined',
          items: getRawItems(ACADEMIC_ENUM),
          margin: 'dense',
          xs: 6,
          hidden:
            (values['candidateTypeId'] === undefined &&
              initialValues['candidateTypeId'] === undefined) ||
            initialValues['candidateTypeId'] === 2,
        },
        {
          id: 'salaryType',
          label: 'Salary Cadence',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          variant: 'outlined',
          items: getRawItems(SALARY_TYPE_ENUM),
          margin: 'dense',
          xs: 3,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'salaryMin',
          label: 'Minimum Salary',
          type: 'autocomplete',
          forceErrorMargin: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined &&
            (values['salaryType'] === undefined || initialValues['salaryType'] === undefined)
              ? true
              : false,
          disableClearable: true,
          variant: 'outlined',
          items: salaryRange,
          filter: (items, values) => {
            return values['salaryMax']
              ? items.filter(item => item.value < values['salaryMax'])
              : items
          },
          margin: 'dense',
          xs: 3,
        },
        {
          id: 'salaryMax',
          label: 'Maximum Salary',
          type: 'autocomplete',
          forceErrorMargin: false,
          disableClearable: true,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined &&
            (values['salaryType'] === undefined || initialValues['salaryType'] === undefined)
              ? true
              : false,
          variant: 'outlined',
          items: salaryRange,
          filter: (items, values) => {
            return values['salaryMin']
              ? items.filter(item => item.value > values['salaryMin'])
              : items
          },
          margin: 'dense',
          xs: 3,
        },
        {
          id: 'salary',
          label: 'Salary (Optional)',
          type: 'money',
          thousandSeparator: true,
          forceErrorMargin: false,
          required: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined &&
            (values['salaryType'] === undefined || initialValues['salaryType'] === undefined)
              ? true
              : false,
          variant: 'outlined',
          margin: 'dense',
          xs: 3,
        },
        {
          id: 'link',
          label: 'External Job Link',
          type: 'text',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          multiline: false,
          required: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'about',
          label: 'About',
          type: 'textEditor',
          initialValue: about ? about : '',
          forceErrorMargin: false,
          variant: 'outlined',
          margin: 'dense',
          placeholder: 'Enter Job Description',
          multiline: true,
          required: false,
          hidden:
            values['candidateTypeId'] === undefined &&
            initialValues['candidateTypeId'] === undefined,
        },
        {
          id: 'aboutRaw',
          hidden: true,
        },
      ]}
    />
  )
}
