import React from 'react'
import {
  GET_SPECIALTIES,
  SET_SPECIALTIES,
  RESIZE_APPBAR,
  RESIZE_VIEWPORT,
  UPDATE_JOB_FILTERS_HEIGHT,
} from 'data/constants'
import { fetchDispatch, sortAscendingKey } from 'data/helpers'
import { useDispatch, useSelector } from 'react-redux'

export const GET_REFERRAL_SOURCES_LOADING = 'GET_REFERRAL_SOURCES_LOADING'

export const GET_REFERRAL_SOURCES_SUCCESS = 'GET_REFERRAL_SOURCES_SUCCESS'

export const GET_REFERRAL_SOURCES_ERROR = 'GET_REFERRAL_SOURCES_ERROR'

export function resizeViewPort(payload) {
  return {
    type: RESIZE_VIEWPORT,
    payload,
  }
}

export function resizeAppBar(payload) {
  return {
    type: RESIZE_APPBAR,
    payload,
  }
}

export function updateFiltersHeight(payload) {
  return {
    type: UPDATE_JOB_FILTERS_HEIGHT,
    payload,
  }
}

export function setSpecialties(accounts) {
  return {
    type: SET_SPECIALTIES,
    payload: accounts,
  }
}

//TODO: This needs to be reformatted. This is used not just for the registration form
export function getSpecialties(dispatch) {
  const path = '/api/candidates/registration_form'
  fetchDispatch({
    path: path,
    method: 'GET',
    onResponse: json =>
      dispatch(
        setSpecialties({
          candidateTypes: json.candidateTypes,
          specialties: json.specialties,
          subspecialties: json.subspecialties,
          referralSources: json.referralSources,
        }),
      ),
  })
  dispatch({ type: GET_SPECIALTIES })
}

export function useSpecialties(open = true) {
  const getSpecialties = useGetSpecialties()
  const specialties = (useSelector(state => state.layout.specialties) || []).sort(
    sortAscendingKey('name'),
  )
  const subspecialties = (useSelector(state => state.layout.subspecialties) || []).sort(
    sortAscendingKey('name'),
  )

  const referralSources = useSelector(state => state.layout.referralSources) || []

  const candidateTypes = useSelector(state => state.layout.candidateTypes) || []

  React.useEffect(() => {
    open && getSpecialties()
  }, [open, getSpecialties])

  return { specialties, subspecialties, referralSources, candidateTypes }
}

export function useGetSpecialties() {
  const dispatch = useDispatch()
  return React.useCallback(() => getSpecialties(dispatch), [dispatch])
}

export function useResizeAppBar() {
  const dispatch = useDispatch()
  return React.useCallback(payload => dispatch(resizeAppBar(payload)), [dispatch])
}

export function useUpdateFiltersHeight() {
  const dispatch = useDispatch()
  return React.useCallback(payload => dispatch(updateFiltersHeight(payload)), [dispatch])
}

export function useAppBarHeight() {
  return useSelector(s => s.layout.appBarHeight)
}

export function useWidth() {
  return useSelector(s => s.layout.width)
}

export function useHeight() {
  return useSelector(s => s.layout.height)
}

export function useLandscape() {
  return useSelector(s => s.layout.height < 400 && s.layout.width > s.layout.height)
}
