import makeStyles from '@mui/styles/makeStyles'
export const useStyles = makeStyles(({ palette }) => ({
  formControl: {
    margin: '4px',
    overflow: 'hidden',
  },
  label: {
    color: 'white',
    zIndex: 1,
    paddingLeft: '5px',
  },
  filterHeaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'left',
    backgroundColor: palette.primary.dark,
    padding: '0px',
    zIndex: 100,
    overflow: 'visible' /* Ensure the element is not clipped */,
    position: 'relative',
  },
  filterWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'left',
    backgroundColor: '#0E7C7B',
    padding: '0px',
    zIndex: 100,
    overflow: 'visible' /* Ensure the element is not clipped */,
    position: 'relative',
    borderRadius: '0px 0px 20px 20px',
  },
  select: {
    backgroundColor: '#0E7C7B',
  },
  selectIcon: {
    color: '#000',
  },
  menuItem: {
    backroundColor: '',
  },
  selectedFilters: {
    marginLeft: '10px',
  },
  selectedCategoryWrapper: {
    margin: '5px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectedFilterWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    border: '1px solid #000048',
    margin: '4px 0',
    backgroundColor: '#FFF',
    width: 'max-content',
  },
  closeButton: {
    minWidth: '30px',
    padding: '6px 0',
    marginLeft: 'auto',
    color: palette.primary.main,
  },
  input: {
    paddingLeft: '5px',
    backgroundColor: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: '140px',
    fontSize: '.8rem',
  },
  inputFocused: {
    backgroundColor: '#fff',
  },
  mobileDropdownContainer: {
    width: '100%',
    position: 'relative',
  },
  mobileDropdownClosed: {
    display: 'none',
  },
  mobileCategoryContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    color: 'black',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: '20px 15px',
    fontSize: '0.875rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  mobileDropdownItem: {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 15px',
    backgroundColor: '#EBF2EE',
  },
  mobileDropdownIcon: {
    marginLeft: 'auto',
    transform: 'rotate(0)',
  },
  mobileDropdownIconOpen: {
    marginLeft: 'auto',
    transform: 'rotate(180deg)',
  },
  mobileDropdownIconEmpty: {
    color: 'grey',
    marginLeft: 'auto',
  },
  mobileDropdown: {
    display: 'none',
  },
  mobileDropdownOpen: {
    display: 'contents',
  },
  mobileSelectedFiltersBackground: {
    backgroundColor: 'white',
  },
  mobileFiltersDropdownHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(27, 27, 27)',
    color: 'white',
    padding: '20px 15px',
  },
  updateFilterButton: {
    color: palette.primary.main,
    backgroundColor: palette.primary.contrastText,
    fontWeight: 'bold',
    height: '32px',
    borderRadius: '0',
    marginTop: '8px',
    marginLeft: props => (props.mobile ? '' : '10px'),
    '&:hover': {
      backgroundColor: palette.primary.light, // Change background color on hover
    },
  },
  clearAllButton: {
    height: '38px',
    marginTop: '9px',
    backgroundColor: palette.primary.contrastText,
    color: palette.primary.main,
    borderRadius: '0',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: palette.primary.contrastText, // Change background color on hover
    },
  },
  moreOpenedContainer: {
    padding: 10,
    borderRadius: 0,
  },
  categoryText: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  mobileFilterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  moreButton: {
    fontWeight: 'bold',
    backgroundColor: 'white',
    fontSize: '.8rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    height: props => (props.mobile ? '' : '32px'),
    width: props => (props.mobile ? '' : '150px'),
    borderRadius: 0,
    marginTop: props => (props.mobile ? '' : '8px'),
    marginLeft: props => (props.mobile ? '' : '5px'),
    padding: props => (props.mobile ? '20px 15px' : '0px 5px 0px 5px'),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: '#FFF', // Change background color on hover
    },
  },
  hiddenFiltersContainer: {
    position: props => (props.mobile ? '' : 'absolute'),
    zIndex: props => (props.mobile ? '' : '999'),
    marginTop: props => (props.mobile ? '' : '45px'),
  },
  moreFilterDropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: props => (props.mobile ? '3px' : ''),
  },
  moreFilterDropdownContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3px',
  },
  moreText: {
    margin: '0 auto',
    marginRight: '-20px',
  },
  desktopFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}))
