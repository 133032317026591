import React from 'react'
import { Grid, Box, Chip, Typography, useTheme, Divider } from '@mui/material'
import GenericDialog from 'components/Dialog/GenericDialog'
import { useDialogState } from 'data/dialog/actions'
import { fetchUserFilters } from 'src/redux/filters'
import { useDispatch, useSelector } from 'react-redux'
import { isEdit } from 'components/Dialog/helpers'
import { getSummaryText } from './helpers'
import { fetchUpdateUserFilter } from 'src/redux/filters'
import { fetchProviderFilterValues } from 'src/redux/filters'
import { getSelectedFiltersFromQueryString } from '../../Filters/helpers'

export const dialogId = 'Alert Dialog'

const cadenceItems = [
  { id: 2, name: 'Weekly' },
  { id: 3, name: 'Monthly' },
]
export default function AlertDialog({ id = dialogId }) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [{ values, initialValues, type }, setState] = useDialogState(id)
  const { userId, email, employerUserId } = useSelector(state => state.identity)
  const { userFilters = [] } = useSelector(state => state.filters)
  const jobFilters = useSelector(state => state.filters.jobFilters)
  let selectedFilters = []
  let selectedUserFilter = null
  let summaryText = null
  let searchParams
  if (values) {
    selectedUserFilter = userFilters.find(k => k.id === values.filterId)
  }

  const availableUserFilters = employerUserId
    ? userFilters.filter(k => k.filterType.name === 'Provider')
    : userFilters

  if (selectedUserFilter && values.cadenceId) {
    summaryText = getSummaryText({
      selectedUserFilter: selectedUserFilter,
      email: email,
      cadenceId: values.cadenceId,
    })
    searchParams = new URLSearchParams(selectedUserFilter.queryString)
    selectedFilters = getSelectedFiltersFromQueryString(searchParams, jobFilters)
  }
  const handleSubmit = values => {
    const data = {
      id: values.filterId,
      alertCadence: values.cadenceId,
      userId: userId,
    }
    dispatch(fetchUpdateUserFilter(data))
    setState()
  }

  React.useEffect(() => {
    dispatch(fetchUserFilters(userId))
    dispatch(fetchProviderFilterValues({}))
  }, [])

  const defaultValues = {
    userId: userId,
    filterId: '',
    cadenceId: '',
  }
  return (
    <GenericDialog
      id={dialogId}
      title='Create New Alert'
      maxWidth='sm'
      subtitle='Get an email when new jobs are available based on your filter criteria.'
      onSubmit={handleSubmit}
      // validate={handleValidation}
      initialValues={isEdit(type) ? initialValues : defaultValues}
      submitLabel='Save'
      cancelLabel='Cancel'
      fields={[
        {
          id: 'id',
          hidden: true,
        },
        {
          id: 'userId',
          hidden: true,
        },
        {
          id: 'filterId',
          label: 'Select your criteria (based on your saved filters)',
          type: 'autocomplete',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          dialogId: dialogId,
          items: availableUserFilters.map(k => ({
            value: k.id,
            label: k.name,
          })),
          margin: 'dense',
          disableClearable: true,
          xs: 12,
        },
        {
          id: 'cadenceId',
          label: 'How often would you like to receive these notifications?',
          type: 'autocomplete',
          required: true,
          forceErrorMargin: false,
          variant: 'outlined',
          dialogId: dialogId,
          items: cadenceItems.map(k => ({
            value: k.id,
            label: k.name,
          })),
          margin: 'dense',
          disableClearable: true,
          xs: 12,
        },
        {
          type: 'genericComponent',
          id: 'filter-info',
          hidden: false,
          Component: () => (
            <Grid>
              <Typography variant='caption'>{summaryText}</Typography>
              {selectedFilters.map(selectedFilter => (
                <Grid
                  container
                  alignItems='center'
                  justifyContent='space-between'
                  sx={{ padding: '5px 10px', width: '100%' }}
                >
                  <Grid item xs={6}>
                    <Typography variant='subtitle1'>
                      {selectedFilter.filterDefinition.displayName}
                    </Typography>
                  </Grid>
                  <Grid container direction='row' item xs={6} justifyContent='flex-end'>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.2 }}>
                      {selectedFilter.selectedValues.map(selectedValue => (
                        <Grid
                          item
                          container
                          direction='row'
                          justifyContent='flex-end'
                          xs={12}
                          aligntext='right'
                        >
                          <Chip
                            sx={{
                              height: '20px',
                              backgroundColor: theme.palette.secondary.light,
                              borderRadius: 0,
                              width: '220px',
                              '& .MuiChip-label': {
                                fontSize: '.8rem',
                              },
                            }}
                            key={selectedValue.name}
                            label={selectedValue.name}
                          />
                        </Grid>
                      ))}
                    </Box>
                  </Grid>
                  <Divider color='secondary' sx={{ height: '1px' }} />
                </Grid>
              ))}
            </Grid>
          ),
        },
      ]}
    />
  )
}
