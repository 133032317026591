import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchGetJob } from 'src/redux/job'
import { getCandidateJobs } from 'data/jobs/actions'
import { CircularProgress, useTheme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useIdentity } from 'pages/Identity/Login'
import JobContent from './JobContent'

const useStyles = makeStyles(({ palette, layout }) =>
  createStyles({
    pageWrapper: {
      display: 'flex',
      justifyContent: 'center',
      background: 'white',
      width: '100%',
      height: '100%',
    },
    submitProgress: {
      color: palette.primary,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -layout.progressSizeLarge / 2,
      marginLeft: -layout.progressSizeLarge / 2,
    },
  }),
)

const JobDetails = () => {
  const dispatch = useDispatch()
  const { job, loading } = useSelector(state => state.job)
  const { jobsList = [] } = useSelector(state => state.jobs)
  const { signedIn } = useIdentity()
  const { id } = useParams()
  const { layout } = useTheme()
  const classes = useStyles()

  useEffect(() => {
    dispatch(fetchGetJob(id))
    if (jobsList.length === 0) {
      dispatch(getCandidateJobs())
    }
  }, [])

  return (
    <div className={classes.pageWrapper}>
      <div className={classes.jobContainer}>
        {loading && Object.keys(job).length === 0 ? (
          <CircularProgress size={layout.progressSizeLarge} className={classes.submitProgress} />
        ) : Object.keys(job).length !== 0 ? (
          <JobContent item={job} signedIn={signedIn} jobs={jobsList} />
        ) : (
          <div>Job not found</div>
        )}
      </div>
    </div>
  )
}

export default JobDetails
