import React from 'react'
import { useDispatch } from 'react-redux'
import { Grid } from '@mui/material'
import * as JobDialog from 'components/Dialog/JobDialog'
import { archiveJob } from 'data/jobs/actions'
import { EditDialogButton, DeleteDialogButton } from 'components/Dialog/DialogButton'

const EditableJobButtons = ({ job }) => {
  const dispatch = useDispatch()
  const archive = job.deletedAt === null
  const initialValues = {
    ...job,
    specialtyIds: job.specialties ? job.specialties.map(s => s.id) : [],
    subspecialtyIds: job.subspecialties ? job.subspecialties.map(s => s.id) : [],
  }
  return (
    <>
      <Grid item xs={2}>
        <div className='joyride_edit_job'>
          <EditDialogButton
            Module={JobDialog}
            size='small'
            tooltip='Edit job'
            initialValues={initialValues}
            mount={false}
          >
            Edit Job
          </EditDialogButton>
        </div>
      </Grid>
      <Grid item xs={2}>
        <div className='joyride_delete_job'>
          <Grid item>
            <DeleteDialogButton
              size='small'
              tooltip={job.deletedAt !== null ? 'Unarchive job' : 'Archive Job'}
              isDeleted={job.deletedAt !== null}
              onClick={() =>
                dispatch(archiveJob({ id: job.id, archive: archive, employerId: job.employer.id }))
              }
            >
              Delete Job
            </DeleteDialogButton>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export default EditableJobButtons
