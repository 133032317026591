export const FilterType = {
  CANDIDATE: 1,
  JOB: 2,
  HOUSING: 3,
}

export const UserType = {
  CANDIDATE: 1,
  EMPLOYER: 2,
}

// valuesResponseKey: response key in filter values api call
// queryStringKey: key in query string for selected filter values
export const filterDefinitions = [
  {
    name: 'specialty',
    displayName: 'Specialties',
    valuesResponseKey: 'specialties',
    queryStringKey: 'specialtyIds',
    isMultiple: true,
    isVisible: () => {
      return false
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: false,
    showMoreFilters: false,
  },
  {
    name: 'subspecialty',
    displayName: 'Subspecialties',
    valuesResponseKey: 'subspecialties',
    queryStringKey: 'subspecialtyIds',
    isMultiple: true,
    isVisible: filterType => {
      return filterType === FilterType.JOB || filterType === FilterType.CANDIDATE
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'city',
    displayName: 'Cities',
    valuesResponseKey: 'cities',
    queryStringKey: 'cities',
    isMultiple: true,
    isVisible: () => {
      return false
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    showMoreFilters: true,
  },
  {
    name: 'state',
    displayName: 'States',
    valuesResponseKey: 'states',
    queryStringKey: 'states',
    isMultiple: true,
    isVisible: filterType => {
      return filterType === FilterType.JOB || filterType === FilterType.CANDIDATE
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'practiceType',
    displayName: 'Practice Types',
    valuesResponseKey: 'practiceTypes',
    queryStringKey: 'practiceTypes',
    isMultiple: true,
    isVisible: filterType => {
      return filterType === FilterType.JOB
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'visa',
    displayName: 'Visas',
    valuesResponseKey: 'visas',
    queryStringKey: 'visaIds',
    isMultiple: true,
    isVisible: filterType => {
      return filterType === FilterType.JOB
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'loanForgiveness',
    displayName: 'Loan Forgiveness',
    valuesResponseKey: 'loanForgiveness',
    queryStringKey: 'loanForgiveness',
    isMultiple: false,
    isVisible: filterType => {
      return filterType === FilterType.JOB
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: true,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'pslfCompatible',
    displayName: 'PSLF Compatible',
    valuesResponseKey: 'pslfCompatible',
    queryStringKey: 'pslfCompatible',
    isMultiple: false,
    isVisible: filterType => {
      return filterType === FilterType.JOB
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: true,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'academicType',
    displayName: 'Academic',
    valuesResponseKey: 'academicTypes',
    queryStringKey: 'academicTypes',
    isMultiple: false,
    isVisible: filterType => {
      return filterType === FilterType.JOB
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'areaType',
    displayName: 'Area',
    valuesResponseKey: 'areaTypes',
    queryStringKey: 'areaTypes',
    isMultiple: false,
    isVisible: filterType => {
      return filterType === FilterType.JOB
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'favorite',
    displayName: 'Is Favorite',
    valuesResponseKey: 'favorite',
    queryStringKey: 'favorite',
    isMultiple: false,
    isVisible: filterType => {
      return filterType === FilterType.JOB
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: true,
    showMoreFilters: true,
  },
  {
    name: 'employerUsers',
    displayName: 'Recruiters',
    valuesResponseKey: 'employerUsers',
    queryStringKey: 'employerUserIds',
    isMultiple: true,
    isVisible: (filterType, userType) => {
      return filterType === FilterType.JOB && userType === UserType.EMPLOYER
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'preferredCity',
    displayName: 'Preferred Cities',
    valuesResponseKey: 'preferredCities',
    queryStringKey: 'preferredCities',
    isMultiple: true,
    isVisible: () => {
      return false
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'preferredState',
    displayName: 'Preferred States',
    valuesResponseKey: 'preferredStates',
    queryStringKey: 'preferredStates',
    isMultiple: true,
    isVisible: filterType => {
      return filterType === FilterType.CANDIDATE
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'hasPhone',
    displayName: 'Phone Provided',
    valuesResponseKey: 'hasPhone',
    queryStringKey: 'hasPhone',
    isMultiple: false,
    isVisible: filterType => {
      return filterType === FilterType.CANDIDATE
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: true,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'hasEmail',
    displayName: 'Email Provided',
    valuesResponseKey: 'hasEmail',
    queryStringKey: 'hasEmail',
    isMultiple: false,
    isVisible: filterType => {
      return filterType === FilterType.CANDIDATE
    },
    isSearchParamVisible: true,
    isSavableFilter: true,
    isYesNo: true,
    clearAll: true,
    showMoreFilters: true,
  },
  {
    name: 'page',
    displayName: 'page',
    valuesResponseKey: 'page',
    queryStringKey: 'page',
    isMultiple: false,
    isVisible: () => {
      return false
    },
    isSavableFilter: false,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: false,
  },
  {
    name: 'pageSize',
    displayName: 'pageSize',
    valuesResponseKey: 'pageSize',
    queryStringKey: 'pageSize',
    isMultiple: false,
    isVisible: () => {
      return false
    },
    isSavableFilter: false,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: false,
  },
  {
    name: 'userFilterAlertId',
    displayName: 'userFilterAlertId',
    valuesResponseKey: 'userFilterAlertId',
    queryStringKey: 'userFilterAlertId',
    isMultiple: false,
    isVisible: () => {
      return false
    },
    isSavableFilter: false,
    isYesNo: false,
    clearAll: true,
    showMoreFilters: false,
  },
  {
    name: 'candidateTypes',
    displayName: 'Profession',
    isSavableFilter: true,
    valuesResponseKey: 'candidateTypes',
    queryStringKey: 'candidateTypeId',
    isMultiple: false,
    clearOthers: true,
    clearAll: false,
    isVisible: () => {
      false
    },
    showMoreFilters: false,
  },
]
