import React from 'react'
import { useDispatch } from 'react-redux'
import LoadingButton from '@mui/lab/LoadingButton'
import { Button, Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import Eligibility from './Eligibility'
import Residency from './Residency'
import Resume from './Resume'
import { fetchTrackEvent, ProductAnalyticsEventTypes } from 'src/redux/productAnalytics'

const Credentials = ({ user, candidate, setActiveStep, activeStep, gotoNextStep }) => {
  const dispatch = useDispatch()
  const [errors, setErrors] = React.useState({})
  const theme = useTheme()
  const below_lg = useMediaQuery(theme.breakpoints.down('md'))

  const initialFormValues = {
    specialties: candidate.specialties[0] || {},
    subspecialties: candidate.subspecialties[0] ? candidate.subspecialties[0] : {},
    residency: candidate.residency,
    residencyEnd: candidate.residencyEnd,
    npiNumber: candidate.npiNumber,
    resume: candidate.resume,
    boardEligibility: candidate.boardEligibility,
    licensedStates: candidate.licensedStates ? candidate.licensedStates : [],
    visaId: candidate.visaId,
    candidateTypeId: candidate.candidateType.id,
  }

  const [values = {}, setValues] = React.useState(initialFormValues)

  React.useEffect(() => {
    setValues(initialFormValues)
  }, [candidate])

  const handleChange = event => {
    const { name, value } = event.target

    setValues(prevValues => {
      const newValues = { ...prevValues, [name]: value }
      if (name === 'candidateTypeId') {
        newValues.specialties = {}
        newValues.subspecialties = {}
      }
      return newValues
    })
  }

  const handleSave = () => {
    const data = {
      residency: values.residency,
      residencyEnd: values.residencyEnd,
      npiNumber: values.npiNumber,
      boardEligibility: values.boardEligibility,
      visaId: values.visaId,
      licensedStates: values.licensedStates,
      id: user.id,
      specialties: [values.specialties.id],
      candidateTypeId: values.candidateTypeId,
      subspecialties:
        Object.keys(values.subspecialties).length > 0 ? [values.subspecialties.id] : [],
    }
    dispatch(
      fetchUpdateCandidate(data, 'Updated your credentials!', null, {
        function: setActiveStep,
        params: activeStep + 1,
      }),
    )
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_credentials_submit_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
  }

  const handleSkip = () => {
    dispatch(
      fetchTrackEvent({
        eventName: 'onboarding_credentials_skip_click',
        eventType: ProductAnalyticsEventTypes.BUTTON_CLICK,
        userId: user.email,
      }),
    )
    gotoNextStep()
  }
  return (
    <>
      <Resume user={user} values={values} loading={candidate.resumeLoading} />
      <Box sx={{ border: '1px solid gray', padding: '20px', marginBottom: '20px' }}>
        <Eligibility
          setErrors={setErrors}
          values={values}
          handleChange={handleChange}
          errors={errors}
        />
        <Residency values={values} handleChange={handleChange} />
      </Box>
      <Grid
        container
        direction='row'
        alignItems='center'
        sx={[
          {
            padding: '10px',
            justifyContent: 'flex-start',
            marginBottom: '20px',
          },
          below_lg && {
            zIndex: 1000,
            justifyContent: 'center',
            marginTop: '50px',
            position: 'fixed',
            bottom: '0px',
            left: '0px',
            right: '0px',
            marginBottom: '0px',
            backgroundColor: theme.palette.secondary.light,
          },
        ]}
      >
        <Grid item sx={{ paddingRight: '20px' }}>
          <Button onClick={handleSkip}>Skip</Button>
        </Grid>
        <Grid item>
          <LoadingButton
            disabled={Object.keys(errors).length > 0}
            type='submit'
            autoFocus
            variant='contained'
            onClick={handleSave}
            loading={candidate.loading}
          >
            Save & Continue
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  )
}

export default Credentials
