import React from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgress,
  Divider,
  Typography,
  Dialog,
  Grid,
  Button,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import makeStyles from '@mui/styles/makeStyles'
import { isEmpty, validateEmail, copyToLower, getLabel } from 'data/helpers'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useWidth } from 'data/layout/actions'
import { useDialogState } from 'data/dialog/actions'
import DialogField, { getValue } from 'components/DialogField'
import { useValues } from 'hooks/useValues'
import { filterOnSubmit, isView } from './helpers'
import { capitalizeFirstLetter } from 'common/helpers'

export const useStyles = makeStyles(theme => ({
  divider: {
    background: '#077187',
    height: 1,
    borderRadius: 8,
    marginLeft: 16,
    marginRight: 16,
    width: '50%',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    border: `5px solid ${theme.palette.primary}`,
  },
  dialogTitle: {
    margin: 0,
    padding: 4,
    paddingLeft: 16,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  dialogActions: {
    background: theme.palette.grey[100],
  },
  submitProgress: {
    color: theme.palette.primary.light,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -theme.layout.progressSize,
    marginLeft: -theme.layout.progressSize,
  },
  renderComponentWrapper: {
    position: 'relative',
    maxWidth: '100vw',
    overflowX: 'hidden',
    paddingBottom: '60px',
  },
  renderComponentActions: {
    background: theme.palette.grey[100],
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: '10px 0',
    width: '100%',
  },
  renderComponentCloseButton: {
    marginRight: '10px',
  },
  closeIcon: {
    textAlign: 'right',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '5px',
  },
}))

const GenericDialog = ({
  id,
  title: Title,
  initialValues: InitialValues = undefined, // If initial values are provided they are merged with the initial values from the state
  maxWidth = 'xs',
  cancelLabel = 'Cancel',
  submitLabel,
  fields = [],
  onSubmit,
  Content = undefined,
  validate,
  subtitle,
  headerTitle,
  alwaysEnabled,
  filterInActive = true, // Filter inactive fields from the onSubmit action, when Field.action = true)
  filterExclude = true, // Filter exclude fields from the onSubmit action, when Field.exclude = true)
}) => {
  const [dialogState, setDialogState] = useDialogState(id)
  const {
    initialValues = InitialValues,
    type,
    open = false,
    loading,
    submitting,
    title = Title,
    RenderComponent = null,
  } = dialogState
  const width = useWidth()
  const mobile = width < 1000

  const [values, setValues, changed, touched, setTouched, mapField] = useValues({
    open,
    initialValues: InitialValues ? InitialValues : initialValues,
    state: dialogState,
  })
  const classes = useStyles({})
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleValidation = () => {
    var errors = copyToLower(dialogState.errors) // Concatenate with server generated errors, ensure all keys start with lowercase letter

    fields.forEach(f => {
      if (f.email && !validateEmail(values[f.id])) {
        errors[f.id] = 'Invalid email format.'
      }
    })

    fields.forEach(f => {
      // A value of false can be a valid value when used in a select field
      if (f.required && values[f.id] !== false && values[f.id] !== 0 && isEmpty(values[f.id])) {
        errors[f.id] = 'Required'
      }
    })

    return errors
  }

  const internalErrors = open ? handleValidation() : {} // Perform standard field validations, required, email, etc
  const externalErrors = open && validate ? validate(values, dialogState, initialValues) : {} // Add any external validations if specified
  const errors = { ...internalErrors, ...externalErrors }
  const errorCount = Object.keys(errors).length

  const handleChange = React.useCallback(
    name => event => {
      const value = event && event.target && event.target.value
      setValues(prev => ({ ...prev, [name]: value }))
      setDialogState(prev => ({ ...prev, values: { ...prev.values, [name]: value }, errors: {} }))
      // Reset any external errors
    },
    [setValues, setDialogState],
  )

  const handleTextEditorChange = React.useCallback(
    () =>
      ({ about, aboutRaw }) => {
        setValues(prev => ({ ...prev, about, aboutRaw }))
        setDialogState(prev => ({ ...prev, errors: {} })) // Reset any external errors
      },
    [setValues, setDialogState],
  )

  const handleBlur = React.useCallback(
    name => () => {
      setTouched(prev => ({ ...prev, [name]: true }))
    },
    [setTouched],
  )

  const values_s = JSON.stringify(values)

  const handleSubmit = React.useCallback(() => {
    if (errorCount > 0) {
      setDialogState(prev => ({ ...prev, showErrors: true }))
    } else {
      onSubmit &&
        onSubmit(filterOnSubmit(JSON.parse(values_s), fields, filterInActive, filterExclude))
    }
  }, [setDialogState, onSubmit, values_s, filterInActive, filterExclude, filterOnSubmit])

  const handleClose = React.useCallback(() => {
    setDialogState(prev => ({ ...prev, open: false, modalSeen: true }))
  }, [setDialogState])

  const fProps = f => ({
    ...f,
    value: getValue(f, values),
    error: (dialogState.showErrors || touched[f.id]) && errors[f.id],
    label: getLabel(f),
    disabled: isView(type) || submitting || f.disabled,
    onChange: f.type === 'textEditor' ? handleTextEditorChange(f.id) : handleChange(f.id),
    onBlur: handleBlur(f.id),
    items: f.filter
      ? f.filter(f.items, values)
      : typeof f.items === 'function'
        ? f.items(values)
        : f.items,
  })

  const contentProps = {
    fields,
    mapField,
    values,
    setValues,
    dialogState,
  }
  const inProgress = loading || submitting
  const disabled = inProgress || errors['loading']
  const { layout } = useTheme()
  return (
    <Dialog
      BackdropProps={{
        style: { backdropFilter: 'blur(1px)', backgroundColor: 'rgba(0,0,0,0.1)' },
      }}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby={`${id}-dialog-title`}
      aria-describedby={`${id}-dialog-description`}
      className={classes.dialog}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      maxWidth={maxWidth}
      fullWidth
    >
      {RenderComponent ? (
        <div className={classes.renderComponentWrapper}>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={headerTitle && { backgroundColor: 'black' }}
          >
            <Grid item sx={{ padding: '0px 10px' }}>
              {headerTitle && <Typography sx={{ color: 'white' }}>{headerTitle}</Typography>}
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon sx={headerTitle && { color: theme.palette.common.white }} />
              </IconButton>
            </Grid>
          </Grid>
          {RenderComponent()}
          {mobile && (
            <DialogActions className={classes.renderComponentActions}>
              <Button
                variant='contained'
                color='secondary'
                onClick={handleClose}
                className={classes.renderComponentCloseButton}
              >
                Close
              </Button>
            </DialogActions>
          )}
        </div>
      ) : (
        <>
          <DialogTitle id={`${id}-dialog-title`} className={classes.dialogTitle}>
            <Typography variant='h6'>
              {title ? title : [capitalizeFirstLetter(type), id].join(' ')}
            </Typography>
          </DialogTitle>
          <Divider className={classes.divider} />
          {Content ? (
            <Content {...contentProps} />
          ) : (
            <DialogContent className={classes.dialogContent}>
              {inProgress && (
                <CircularProgress
                  size={layout.progressSize * 2}
                  className={classes.submitProgress}
                />
              )}
              <Grid container alignItems='center' spacing={1}>
                <Typography variant='caption'>{subtitle}</Typography>
                {fields.map(f => (
                  <Grid item key={f.id} xs={f.xs ? f.xs : 12}>
                    {f.renderField ? (
                      f.renderField(fProps(f), values)
                    ) : (
                      <DialogField {...fProps(f)} />
                    )}
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
          )}
          <DialogActions className={classes.dialogActions}>
            <Button variant='contained' color='inherit' onClick={handleClose}>
              {cancelLabel}
            </Button>
            {!isView(type) && (
              <Button
                autoFocus
                disabled={alwaysEnabled ? false : disabled || !changed}
                variant='contained'
                onClick={handleSubmit}
              >
                {submitLabel || type}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

GenericDialog.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  validate: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
}

export default GenericDialog
