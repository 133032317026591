import * as React from 'react'
import {
  useTheme,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CircularProgress,
  TableSortLabel,
  Button,
  Grid,
} from '@mui/material'
import { useStyles } from 'common/styles'
import { useApplications } from './useApplications'
import { transformRowsForTable } from './helpers'
import { columns } from './config'
import ApplicationDrawer from './ApplicationDrawer'
import ResizableTableCell from './ResizableTableCell'

export const Applications = () => {
  const theme = useTheme()
  const classes = useStyles()
  const {
    loading,
    availableTags,
    rows,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    order,
    orderBy,
    handleRequestSort,
    drawerOpen,
    handleDrawerClose,
    handleStatusChange,
    selectedRow,
    handleRowClick,
    statuses,
    handleFavoriteChange,
    handleDelete,
    candidateId,
    setDrawerOpen,
    setSelectedRow,
    userId,
  } = useApplications()

  const formattedRows = transformRowsForTable({
    rows,
    onUpdateStatus: handleStatusChange,
    statuses,
    handleFavoriteChange,
    handleDelete,
    candidateId,
    userId,
  })

  const getRawRow = selectedFormattedRow => {
    return rows.find(row => row.id === selectedFormattedRow.id)
  }

  const addNewApplication = () => {
    setSelectedRow(null)
    setDrawerOpen(true)
  }

  return loading ? (
    <CircularProgress className={classes.submitProgress} />
  ) : (
    <Grid container item xs={12} justifyContent='center' sx={{ marginTop: '20px' }}>
      <Grid container item xs={11}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          marginBottom='20px'
          spacing={2} // Adds spacing between items
        >
          <Grid item xs={12} sm='auto'>
            <Typography variant='h6'>My Jobs</Typography>
          </Grid>
          <Grid item xs={12} sm='auto'>
            <Button variant='contained' color='primary' onClick={addNewApplication}>
              Add job
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant='body1' color='textSecondary' sx={{ marginBottom: '10px' }}>
            Keep track of your job applications here. Add jobs from MyStethi or other systems to
            streamline your job management. Organize by tags, status, location, and notes for easier
            tracking.
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={11}>
        <Paper sx={{ width: '100%', marginBottom: '50px' }} className={classes.tablePaper}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <ResizableTableCell
                      key={column.id}
                      align={column.align}
                      isHeader={true}
                      fitheader={column.fitheader}
                      sortDirection={orderBy === column.id ? order : false}
                      sx={{
                        '&.MuiTableCell-stickyHeader': {
                          backgroundColor: theme.palette.secondary.light,
                        },
                      }}
                    >
                      {column.sortable ? (
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : 'asc'}
                          onClick={event => handleRequestSort(event, column.id)}
                        >
                          <Typography variant='body1'>{column.label}</Typography>
                        </TableSortLabel>
                      ) : (
                        <Typography variant='body1'>{column.label}</Typography>
                      )}
                    </ResizableTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {formattedRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <TableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => handleRowClick(getRawRow(row))}
                      sx={{ cursor: 'pointer' }}
                    >
                      {columns.map(column => {
                        const value = column.id === 'status' ? row.statusDropdown : row[column.id]
                        return (
                          <ResizableTableCell
                            key={column.id}
                            align={column.align}
                            sx={{ cursor: 'pointer' }}
                          >
                            {value}
                          </ResizableTableCell>
                        )
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ApplicationDrawer
            open={drawerOpen}
            onClose={handleDrawerClose}
            selectedRow={selectedRow}
            statuses={statuses}
            availableTags={availableTags}
          />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Applications
