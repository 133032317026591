import React from 'react'
import { checkEmpty, imageAddress } from 'data/helpers'
import PhysicianPlaceholderImage from 'static/images/profile-image-placeholder.jpg'
import { getLocation } from '../Jobs/helpers'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import SchoolIcon from '@mui/icons-material/School'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import GradingIcon from '@mui/icons-material/Grading'
import NumbersIcon from '@mui/icons-material/Numbers'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import ArticleIcon from '@mui/icons-material/Article'
import CircleIcon from '@mui/icons-material/Circle'
import { splitCamelCaseToString } from 'data/helpers'
import { formatDate } from '../../data/helpers'
export const getSpecialty = (specialty, subspecialty) => {
  let specialtyCombo = specialty
  if (subspecialty) {
    specialtyCombo = specialty + ': ' + subspecialty
  }
  return specialtyCombo
}

export const getPhysicianProfileImage = profileImage => {
  const { key, fileName } = checkEmpty(profileImage) ? {} : profileImage
  const image = !checkEmpty(key) ? imageAddress(key, fileName) : PhysicianPlaceholderImage
  return image
}

export const getResidency = (residency, residencyEnd) => {
  let residencyCombo = 'Residency: '
  if (residency) {
    residencyCombo = residencyCombo + residency
  }
  if (residencyEnd) {
    residencyCombo = residencyCombo + '<br/>Complete on: ' + new Date(residencyEnd).toDateString()
  }
  return residencyCombo
}

export const getBoardingEligibility = boardEligibility => {
  if (boardEligibility) return splitCamelCaseToString(boardEligibility)
}

export const getAvailabilityStatus = ({ searchStatus, availabilityDate }) => {
  let result
  if (searchStatus) {
    result = `${splitCamelCaseToString(searchStatus)}  `
  }
  if (searchStatus && availabilityDate) {
    result = result + ` | `
  }
  if (availabilityDate) {
    result = result + `Available on: ${formatDate(availabilityDate)}`
  }
  return result
}

export const getSearchStatusColor = searchStatus => {
  switch (searchStatus) {
    case 'ActivelyLooking':
      return 'green'
    case 'PassivelyLooking':
      return 'orange'
    case 'NotLooking':
      return 'red'
    default:
      return 'black'
  }
}

export const getRequiresVisaText = requiresVisa => {
  if (requiresVisa) {
    return 'Requires Visa'
  }
}

export const getPhysicianListItems = physician => {
  const fields = []
  if (physician.subspecialties && physician.subspecialties.length > 0) {
    physician.subspecialty = physician.subspecialties[0].name
  }
  Object.keys(physician).forEach(key => {
    switch (key) {
      case 'city':
        fields.push({
          text: `Current: ${getLocation(physician.city, physician.state)}`,
          icon: <LocationOnIcon />,
          show: getLocation(physician.city, physician.state) !== null,
        })
        break
      case 'preferredCity':
        fields.push({
          text: `Preferred:  ${getLocation(physician.preferredCity, physician.preferredState)}`,
          icon: <AddLocationAltIcon />,
          show: getLocation(physician.preferredCity, physician.preferredState) !== undefined,
        })
        break
      case 'residency':
        fields.push({
          text: (
            <div
              dangerouslySetInnerHTML={{
                __html: getResidency(physician.residency, physician.residencyEnd),
              }}
            />
          ),
          icon: <SchoolIcon />,
          show: physician.residency !== null,
        })
        break
      case 'npiNumber':
        fields.push({
          text: `NPI: ` + physician.npiNumber,
          icon: <NumbersIcon />,
          show: physician.npiNumber !== null,
        })
        break
      case 'boardEligibility':
        fields.push({
          text: `${getBoardingEligibility(physician.boardEligibility)}`,
          icon: <GradingIcon />,
          show: physician.boardEligibility !== null,
        })
        break
      case 'licensedStates':
        fields.push({
          text: `Licensed States: ` + physician.licensedStates,
          icon: <WhereToVoteIcon />,
          show: physician.licensedStates !== null,
        })
        break
      case 'requiresVisa':
        fields.push({
          text: `Visa Requirement: ` + physician.requiresVisa,
          icon: <ArticleIcon />,
          show: physician.requiresVisa === true,
        })
        break
      case 'availabilityDate':
        fields.push({
          text: getAvailabilityStatus({
            searchStatus: physician.searchStatus,
            availabilityDate:
              physician.availabilityDate && new Date(physician.availabilityDate).toDateString(),
          }),
          icon: (
            <CircleIcon size='small' sx={{ color: getSearchStatusColor(physician.searchStatus) }} />
          ),
          show: physician.availabilityDate !== null,
        })
        break
      case 'specialties':
        fields.push({
          text:
            physician.specialties.length > 0 &&
            getSpecialty(physician.specialties[0].name, physician.subspecialty),
          icon: <LocalHospitalIcon />,
          show: false,
        })
        break
    }
  })
  return fields
}
